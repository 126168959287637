import { Col } from "react-bootstrap";
import { order_propositions } from "tools/order_propositions";
import { TypeEvent } from "types/DaTimeEvents";

import { is_rejected_by_someone_else } from "tools/propositions/status";
import { TypeProposition } from "types/Propositions";
import { AddProposition } from "./AddDoodlePropRow";
import { DoodlePropRow } from "./DoddlePropRow";

// le même que dans calendar_invited
type InvitedCalendarProps = {
  // id_meeting: string;
  id_user: string;
  id_meeting: string;
  meeting_name: string;
  proposMeeting: TypeProposition[];
  setProposMeeting: (propositions: TypeProposition[]) => void;
  setViewCalendar: (view: boolean) => void;
  events_sup: TypeEvent[];
};

export const DoodlePropositions = ({
  id_user,
  id_meeting,
  meeting_name,
  proposMeeting,
  setProposMeeting,
  setViewCalendar,
  events_sup,
}: InvitedCalendarProps) => {
  return (
    <>
      <h3> Propositions </h3>
      <Col>
        <div style={{ margin: "2px", marginLeft: "10px" }}>
          {proposMeeting
            ? order_propositions(proposMeeting).map((prop: TypeProposition) => {
                if (!is_rejected_by_someone_else(prop, id_user)) {
                  return (
                    <DoodlePropRow
                      key={prop.id}
                      prop={prop}
                      id_user={id_user}
                      meeting_name={meeting_name}
                      proposMeeting={proposMeeting}
                      setProposMeeting={setProposMeeting}
                    ></DoodlePropRow>
                  );
                }
                return null as never;
              })
            : null}
          <AddProposition
            id_user={id_user}
            id_meeting={"20"}
            proposMeeting={proposMeeting}
            setProposMeeting={setProposMeeting}
            setViewCalendar={setViewCalendar}
          ></AddProposition>
        </div>
      </Col>
    </>
  );
};
