import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { from_Proposition_to_Event } from "format/change_format";
import { useGetUserIdQuery } from "store/features/user";
import { CalendarProposition } from "./calendar_proposition";

import { postAgendIAGetCreneau, requestDataType } from "api/agendIA";
import { postChronopt } from "api/postChronopt";
import { downloadBlob } from "components/ics/utils";
import { propositions_to_ics } from "format/to_ics";
import { TextPropositions } from "modules/TextPropositions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCreateEventMutation } from "store/features/events";
import {
  useGetMeetingByIdQuery,
  useUpdateMeetingMutation,
} from "store/features/meetings";
import { useGetMyMeetingByIDQuery } from "store/features/participants";
import {
  useCreatePropositionMutation,
  useDeletePropositionMutation,
  useGetPropositionsOfUserQuery,
  useUpdatePropositionMutation,
} from "store/features/propositions";
import { ExcludedSlotsSelector } from "store/selectors";
import { addTwoMonthsToDate } from "tools/dates";
import { TypeProposition } from "types/Propositions";

export const InputPropositionsMeeting = () => {
  let navigate = useNavigate();
  const { id_meeting } = useParams();

  const { data: user_id } = useGetUserIdQuery();
  const [createEvent] = useCreateEventMutation();

  const [createProposition] = useCreatePropositionMutation();
  const [deleteProposition] = useDeletePropositionMutation();
  const [updateProposition] = useUpdatePropositionMutation();
  const [updateMeeting] = useUpdateMeetingMutation();

  const bh = useSelector(ExcludedSlotsSelector);

  const { data: propositions } = useGetPropositionsOfUserQuery(user_id);
  const { data: meetingPage } = useGetMeetingByIdQuery(id_meeting);
  const { data: myMeeting } = useGetMyMeetingByIDQuery({
    id_meeting,
    id_person: user_id,
  });

  const [proposMeeting, setProposMeeting] = useState<TypeProposition[]>([]);
  const [proposOtherMeeting, setProposOtherMeeting] = useState<
    TypeProposition[]
  >([]);

  useEffect(() => {
    if (propositions) {
      setProposMeeting(
        propositions.filter((propos) => propos.id_meeting === id_meeting)
      );
      setProposOtherMeeting(
        propositions.filter((propos) => propos.id_meeting !== id_meeting)
      );
    }
  }, [propositions, id_meeting]);

  const onRequest = async () => {
    const agendaIA_parameters = {
      id_meeting: id_meeting,
      userId: user_id,
      duree: myMeeting ? myMeeting["my_duration"] : 60,
      debut: meetingPage
        ? meetingPage.limitStartDate
        : new Date().toISOString().slice(0, 10),
      fin: meetingPage
        ? meetingPage.limitEndDate
        : addTwoMonthsToDate().toISOString().slice(0, 10),
      nb_choices: 5,
      excluded_slots: bh,
      excluded_days: [],
    } as requestDataType;

    await postAgendIAGetCreneau(agendaIA_parameters).then((reponse_json) => {
      if (reponse_json) {
        console.log("retour de DaTime", reponse_json);
        const to_local = reponse_json.map((item: TypeProposition) => {
          let creneau = {
            ...item,
            id_meeting: id_meeting ? id_meeting : "",
            submitted_to: user_id ? [user_id] : [],
            validated_by: user_id ? [user_id] : [],
            title: "Proposition faite par DaTime",
            is_new: false,
          };
          return creneau;
        });

        setProposMeeting([...proposMeeting, ...to_local]);
      }
    });
  };

  return (
    <>
      <br />
      <Container>
        <Row>
          <h2>Entrez vos disponibilités facilement </h2> <br />
          <h3>pour prendre le rendez-vous qui vous convient </h3>
          {/* <p
            style={{
              backgroundColor: "rgba(240, 50, 90, 0.5)",
              margin: "10px",
            }}
          >
            Toutes les informations que vous entrez ici ne sont connues que de
            vous et ne sont pas partagées{" "}
          </p> */}
        </Row>
        {/* <Row style={{ margin: "10px" }}>
          {" "}
          Pour définir un plage d'indisponibilité, cliquer pour créer un
          créneau. Cliquer une deuxième fois pour préciser que c'est une
          disponibilité secondaire. Un click de plus supprimer la proposition.
        </Row>

        <Row style={{ margin: "10px" }}>
          <strong>
            Allez sur <a href="/my-agendas">votre agenda</a> pour modifier vos
            disponibilités, importer vos agendas, etc.
          </strong>
        </Row> */}

        <Row>
          <Col className="align-items-center" sm={8}>
            {id_meeting && user_id ? (
              <CalendarProposition
                id_meeting={id_meeting}
                id_user={user_id}
                proposMeeting={proposMeeting}
                setProposMeeting={setProposMeeting}
                proposOtherMeeting={proposOtherMeeting}
              ></CalendarProposition>
            ) : null}
            <br />
            <Row>
              <br />
              {/* <ButtonSearchCreneau /> */}
            </Row>
          </Col>
          <Col className="align-items-center">
            <Row className="align-items-center">
              {user_id ? (
                <Row style={{ padding: "8px", margin: "5px" }}>
                  {" "}
                  <br />{" "}
                  <Button
                    variant="secondary"
                    id="send_to_agendIA"
                    onClick={onRequest}
                  >
                    Afficher les créneaux suggérés par DaTime{" "}
                  </Button>
                </Row>
              ) : null}

              <Button
                style={{ padding: "8px", margin: "10px" }}
                variant="secondary"
                disabled={
                  proposMeeting?.filter((item) => item.availability === 0)
                    .length === 0
                }
                onClick={() => {
                  proposMeeting?.forEach((proposition) => {
                    if (proposition.availability === 0) {
                      if (user_id) {
                        let new_event = from_Proposition_to_Event(
                          proposition,
                          user_id
                        );
                        createEvent(new_event);

                        // deleteProposition(proposition.id);
                      }
                    }
                    setProposMeeting(
                      proposMeeting?.filter((item) => item.availability > 0)
                    );
                  });
                }}
              >
                {" "}
                Retenir les créneaux rejeté pour toutes les réunions
              </Button>

              <Button
                style={{ padding: "8px", margin: "10px" }}
                disabled={proposMeeting.length === 0}
                variant="secondary"
                onClick={() => {
                  setProposMeeting([]);
                }}
              >
                {" "}
                Réinitialiser{" "}
              </Button>

              <Button
                style={{ padding: "2px", margin: "10px" }}
                disabled={
                  proposMeeting?.filter((item) => item.availability > 0)
                    .length === 0
                }
                onClick={() => {
                  proposMeeting?.forEach((proposition) => {
                    let new_proposition = { ...proposition, is_new: false };
                    createProposition(new_proposition);
                  });

                  if (id_meeting) {
                    // postChronopt(id_meeting, "next");
                    navigate("/meeting/invitations/" + id_meeting);
                    if (meetingPage) {
                      updateMeeting({ ...meetingPage, state: "en_cours" });
                    }
                    postChronopt(id_meeting, "next");
                  } else {
                    navigate("/meeting/error");
                    throw new Error(
                      "Problème dans l'association de la réunion"
                    );
                  }
                }}
              >
                {" "}
                Proposer ces créneaux
              </Button>

              {id_meeting ? (
                <TextPropositions propositions={proposMeeting} />
              ) : (
                "pas"
              )}
              {/* <Button
                style={{ padding: "8px", margin: "10px" }}
                onClick={() => {
                  navigate("/meeting/" + id_meeting);
                  // dispatch(flushPropositions())
                }}
              >
                {" "}
                Revenir à l'organisation de la réunion
              </Button> */}
            </Row>
            <Button
              style={{ padding: "8px", margin: "10px" }}
              disabled={proposMeeting?.length === 0}
              onClick={() => {
                if (proposMeeting && myMeeting) {
                  const url = propositions_to_ics(
                    proposMeeting.filter((item) => item.availability > 1),
                    "option" + myMeeting.my_object
                  );
                  const blob: Blob = new Blob([url], {
                    type: "text/calendar;charset=utf-8",
                  });
                  const filename = myMeeting
                    ? "options-datime-" + myMeeting["my_object"] + ".ics"
                    : "options-datime.ics";
                  downloadBlob(blob, filename);
                }
              }}
            >
              Importer les propositions à mon agenda
            </Button>
          </Col>
        </Row>

        {/* <SelectContraintes />
        <SelectPreferences /> */}
      </Container>
    </>
  );
};
