import { TypeProposition } from "types/Propositions";

export const intialize_user_opinion = (
  prop: TypeProposition,
  id_user: string
): TypeProposition => {
  let all_opinion = prop["validated_by"].concat(
    prop["possible_by"],
    prop["rejected_by"]
  );
  if (all_opinion.includes(id_user)) {
    return prop;
  }
  return { ...prop, validated_by: [...prop["validated_by"], id_user] };
};
