import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { postChronopt } from "api/postChronopt";
import { downloadBlob } from "components/ics/utils";
import { propositions_to_ics } from "format/to_ics";
import ImportAgenda from "modules/import_other_agenda";
import { FirstVisitModal } from "pages/invited/modal_first_visit";
import ReactGA from "react-ga4";
import { useGetMeetingByIdQuery } from "store/features/meetings";
import {
  useGetMeetingOrganisatorQuery,
  useGetMyMeetingByIDQuery,
  useUpdateParticipantsMutation,
} from "store/features/participants";
import {
  useCreatePropositionMutation,
  useGetPropositionsOfMeetingQuery,
} from "store/features/propositions";

import { intialize_user_opinion } from "tools/propositions/initialize";
import { TypeEvent } from "types/DaTimeEvents";
import { TypeProposition } from "types/Propositions";
import { DoodlePropositions } from "./Doodle/Main";
import { OnLeaveInvitedModal } from "./ModalLeave";
import { ButtonSendProposition } from "./SendPropositionsButton";
import { InvitedCalendar } from "./calendar_invited";

export const InvitedPage = () => {
  // pour l'instant, on affiche juste le texte
  // pour en faire une disponibilité on met dans une base à côté

  const { id_meeting, id_person } = useParams();

  const [createProposition] = useCreatePropositionMutation();
  const [modalOn, setModalOn] = useState(false);
  const [modalLeave, setModalLeave] = useState(false);
  const [viewCalendar, setViewCalendar] = useState(false);
  const handleClose = () => setModalOn(false);

  const [invitedEvents, setInvitedEvents] = useState<TypeEvent[]>([]);
  const [googleEvents, setGoogleEvents] = useState<TypeEvent[]>([]);
  const [propositionDaTime, setPropositionDaTime] = useState<TypeProposition[]>(
    []
  );
  const [proposMeeting, setProposMeeting] = useState<TypeProposition[]>([]);

  const { data: MeetingPage } = useGetMeetingByIdQuery(id_meeting);
  const { data: organisator } = useGetMeetingOrganisatorQuery(id_meeting);
  const { data: propositions } = useGetPropositionsOfMeetingQuery(id_meeting);

  const [updateParticipant] = useUpdateParticipantsMutation();
  const { data: invited } = useGetMyMeetingByIDQuery({
    id_meeting,
    id_person,
  });

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string);
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: "/meeting/invitations",
      title: "InvitationsPage",
    });
  }, []);

  useEffect(() => {
    if (propositions && id_person) {
      let proposition_meeting = propositions.filter(
        (propos) => propos.id_meeting === id_meeting
      );
      let with_user_by_default = proposition_meeting.map((prop) => {
        return intialize_user_opinion(prop, id_person);
      });
      setProposMeeting(with_user_by_default);
    }
  }, [propositions, id_meeting, id_person]);

  useEffect(() => {
    if (invited) {
      updateParticipant({ ...invited, connected_date: new Date() });
    }
  }, [invited]);

  useEffect(() => {
    if (googleEvents) {
      setInvitedEvents(googleEvents);
    }
  }, [googleEvents]);

  const ButtonIcs = () => {
    return (
      <Button
        style={{ padding: "8px", margin: "10px" }}
        disabled={proposMeeting?.length === 0}
        onClick={() => {
          // on pourrait ajouter MeetingPage pour récupérer le nom comme on le fait dans la page DaTimer mais non
          // il faut régler les RLS de supabase d'abord
          if (proposMeeting) {
            const url = propositions_to_ics(
              proposMeeting.filter((item) => item.availability > 0),
              "option via DaTime"
            );
            const blob: Blob = new Blob([url], {
              type: "text/calendar;charset=utf-8",
            });
            const filename = "options-datime.ics";
            downloadBlob(blob, filename);
          }
        }}
      >
        Ajouter les options à mon agenda
      </Button>
    );
  };

  return (
    <>
      <FirstVisitModal></FirstVisitModal>
      <OnLeaveInvitedModal
        footer={<ButtonIcs />}
        modalOn={modalLeave}
        handleClose={() => setModalLeave(false)}
      />
      <br />
      <Row>
        {MeetingPage ? (
          <div>
            <h4>
              Vous êtes invité à la réunion :{" "}
              <strong> {MeetingPage?.object} </strong> par{" "}
              {organisator?.name ? organisator.name : "l'organisateur"}
            </h4>
            {/* <h4>Vous êtes invité par {MeetingPage?.organizer} </h4> */}
          </div>
        ) : null}
      </Row>
      <Row>
        {!propositions || propositions?.length === 0 ? (
          <div>
            <h1>Aucune proposition n'a été faite pour le moment</h1>
            <p>
              {" "}
              Peut-être que les personnes avant vous ont simplement indiquées
              qu'elles ne pouvaient pas être là ou que quelqu'un a oublié de
              faire des propositions ou alors qu'elles sont toutes passées.
            </p>
            <p>
              Ca vous laisse la possibilité d'être le premier à proposer vos
              disponibilités, les autres invités à la réunion seront contactés
              par la suite{" "}
            </p>
          </div>
        ) : (
          <h2>Entrez vos disponibilités </h2>
        )}
      </Row>

      <hr />

      <Row>
        <Col className="align-items-center" sm={8}>
          {id_person && id_meeting ? (
            viewCalendar ? (
              <InvitedCalendar
                id_user={id_person}
                id_meeting={id_meeting}
                proposMeeting={proposMeeting}
                setProposMeeting={setProposMeeting}
                events_sup={invitedEvents}
              ></InvitedCalendar>
            ) : (
              <DoodlePropositions
                id_user={id_person}
                id_meeting={id_meeting}
                meeting_name={MeetingPage ? MeetingPage.object : "ce meeting"}
                proposMeeting={proposMeeting}
                setProposMeeting={setProposMeeting}
                setViewCalendar={setViewCalendar}
                events_sup={invitedEvents}
              />
            )
          ) : null}
        </Col>
        <Col>
          <Row>
            {id_person ? (
              <ButtonSendProposition
                propositions={proposMeeting}
                id_user={id_person}
                on_end={() => {
                  if (invited) {
                    console.log("ici");
                    updateParticipant({ ...invited, answer_date: new Date() });
                  }
                  setModalLeave(true);
                  if (id_meeting) {
                    postChronopt(id_meeting, "next");
                  }
                }}
              />
            ) : (
              <ButtonSendProposition
                propositions={proposMeeting}
                id_user="fake_user"
                on_end={() => {}}
              />
            )}
          </Row>

          <Row style={{ marginTop: "10px", justifyContent: "center" }}>
            <Button
              style={{ marginTop: "5px" }}
              onClick={() => {
                console.log("Bien définir les créneaux que l'on veut retirer");
              }}
            >
              Réinitialiser
            </Button>
          </Row>
          <Row
            size={1}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <h5>
              {" "}
              Agenda chargé ? Envie d'entrer des propositions facilement ?
              <strong> Passez en vue calendrier </strong>
            </h5>

            <Form style={{ width: "250px" }}>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Vue calendrier"
                checked={viewCalendar}
                onChange={() => setViewCalendar(!viewCalendar)}
              />
            </Form>
          </Row>
          {viewCalendar ? (
            <div>
              <Row>
                <p>
                  Cliquer une fois pour dire que le créneau n'a pas votre
                  préférence, deux fois pour dire qu'il ne vous convient pas{" "}
                </p>
                <hr />
              </Row>
            </div>
          ) : (
            <p> </p>
          )}
          <Row style={{ marginTop: "20px", justifyContent: "center" }}>
            {id_person ? (
              <ImportAgenda user_id={null} setEvents={setGoogleEvents} />
            ) : null}
          </Row>
        </Col>
      </Row>
    </>
  );
};
