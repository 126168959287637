import type { TypeEvent } from "../types/DaTimeEvents";

let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

// let EVENTS_GOOGLE = [
// 	{
// 	url: 'https://calendar.google.com/calendar/ical/julien.datime%40gmail.com/private-8d276d134e741cd0840bd5c315d1089e/basic.ics',
//     	format: 'ics'
//     	}
// ]

export const INITIAL_EVENTS: TypeEvent[] = [
  // {
  //     id: createId(),
  //     user_id: "default",
  //     title: 'All-day event',
  //     start: todayStr,
  //     end: todayStr + 'T01:30:00',
  //     SharedWith: ['1', "2"]
  // },
  // {
  //     id: createId(),
  //     user_id: "default",
  //     title: 'Timed event',
  //     start: todayStr + 'T12:00:00',
  //     end: todayStr + 'T17:00:00',
  //     SharedWith: ['1']
  // },
  // {
  //     id: createId(),
  //     user_id: "default",
  //     title: 'All Day Event move',
  //     // color: 'blue',
  //     start: todayStr + 'T15:00:00',
  //     end: todayStr + 'T16:30:00',
  //     // display: 'inverse-background',
  //     // groupId: 'dispo',
  //     SharedWith: ['1']
  // },
  // {
  //     id: createId(),
  //     user_id: "default",
  //     title: 'Long Event',
  //     start: todayStr,
  //     end: todayStr + 'T02:30:00',
  //     // groupId: 'dispo',
  //     SharedWith: ['1']
  //     // display: 'inverse-background',
  // },
  // {
  //     id: createId(),
  //     user_id: "default",
  //     title: 'Long Event 2 ',
  //     start: todayStr  + 'T09:30:00',
  //     end: todayStr + 'T12:30:00',
  //     // groupId: 'dispo',
  //     SharedWith: ['2']
  //     // display: 'inverse-background',
  // },
  // // on supprime l'utilisation de rrule
  // // {
  // //   id: createId(),
  // //   groupId: createId(),
  // //   title: 'utilise rrule',
  // //   rrule: {
  // //       freq: 'weekly',
  // //       interval: 2,
  // //       byweekday: [ 'mo', 'fr' ],
  // //       dtstart: todayStr + 'T10:30:00', // will also accept '20120201T103000'
  // //       until: '2024-06-01' // will also accept '20120201'
  // //           },
  // //   // display: 'inverse-background',
  // //   SharedWith: ['1']
  // // },
];
