import { Col, Form, Row } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { durations } from "data/durations";
import { fr } from "date-fns/locale/fr";
import { InvitedMeetingType } from "types/Dater";
registerLocale("fr", fr);

type PersonnalizationMeetingProps = {
  myMeeting: InvitedMeetingType | null;
  setMyMeeting: any; // () => void;
  duration_meeting: number;
};

export const PersonnalizationMeetingForm = ({
  myMeeting,
  setMyMeeting,
  duration_meeting,
}: PersonnalizationMeetingProps) => {
  return (
    <Form style={{ padding: "2rem" }}>
      { process.env.REACT_APP_ENV !== "production" ? 
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={7}>
            Voulez-vous définir un autre nom, connu seulement de vous pour
            désigner la réunion ?
          </Form.Label>
          <Col sm={5}>
            <Form.Control
              type="text"
              id="inputObjectMyMeeting"
              value={myMeeting ? myMeeting["my_object"] : ""}
              placeholder="Nom privé de ma réunion"
              onChange={(e) => {
                setMyMeeting({ ...myMeeting, my_object: e.target.value });
              }}
            />
          </Col>
        </Form.Group> : null 
      }

      <Form.Group as={Row} className="mb-3">
        <Form.Label as="legend" column sm={7}>
          Le rendez-vous est prévu pour durer {duration_meeting} minutes.
          <br />
          Combien de temps voulez-vous bloquer dans votre agenda (pour inclure
          le temps de trajet avant ou après ou de la préparation) ?
        </Form.Label>
        <Col sm={5}>
          <Form.Select
            aria-label="Default select example"
            id="inpuTypeMeeting"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setMyMeeting({
                ...myMeeting,
                my_duration: parseInt(e.target.value),
              });
            }}
          >
            <option>Choisir la durée de la réunion</option>
            {durations.map((item) => {
              if (item["minutes"] >= duration_meeting) {
                return (
                  <option value={item["minutes"]} key={item["value"]}>
                    {" "}
                    {item["label"]}{" "}
                  </option>
                );
              }
            })}
          </Form.Select>
        </Col>
      </Form.Group>
    </Form>
  );
};
