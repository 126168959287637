import { Col, Form, Row } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { durations } from "data/durations";
import { fr } from "date-fns/locale/fr";

import { useGetMeetingByIdQuery } from "store/features/meetings";
import {
  useGetMyMeetingByIDQuery,
  useUpdateParticipantsMutation,
} from "store/features/participants";
import { useGetUserIdQuery } from "store/features/user";
registerLocale("fr", fr);

type PersonnalizationMeetingProps = {
  id_meeting: string;
};

export const PersonnalizationMeetingForm = ({
  id_meeting,
}: PersonnalizationMeetingProps) => {
  const { data: user_id } = useGetUserIdQuery();
  const { data: meetingPage } = useGetMeetingByIdQuery(id_meeting);
  const { data: myMeeting } = useGetMyMeetingByIDQuery({
    id_meeting,
    id_person: user_id,
  });
  const [updateMyMeeting] = useUpdateParticipantsMutation();

  return (
    <Form style={{ padding: "2rem" }}>
      {process.env.REACT_APP_ENV !== "production" ? (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={7}>
            Voulez-vous définir un autre nom, connu seulement de vous pour
            désigner la réunion ?
          </Form.Label>
          <Col sm={5}>
            <Form.Control
              type="text"
              id="inputObjectMyMeeting"
              // value={myMeeting ? myMeeting["my_object"] : ""}
              placeholder="Nom privé de ma réunion"
              onBlur={(e) => {
                if (myMeeting) {
                  updateMyMeeting({ ...myMeeting, my_object: e.target.value });
                }
              }}
            />
          </Col>
        </Form.Group>
      ) : null}

      <Form.Group as={Row} className="mb-3">
        <Form.Label as="legend" column sm={7}>
          Le rendez-vous est prévu pour durer{" "}
          {meetingPage ? meetingPage["duration"] : 60} minutes.
          <br />
          Combien de temps voulez-vous bloquer dans votre agenda (pour inclure
          le temps de trajet avant ou après ou de la préparation) ?
        </Form.Label>
        <Col sm={5}>
          <Form.Select
            aria-label="Default select example"
            id="inpuTypeMeeting"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (myMeeting) {
                updateMyMeeting({
                  ...myMeeting,
                  my_duration: parseInt(e.target.value),
                });
              }
            }}
          >
            <option>Choisir la durée de la réunion</option>
            {durations.map((item) => {
              if (
                item["minutes"] >= (meetingPage ? meetingPage["duration"] : 60)
              ) {
                return (
                  <option value={item["minutes"]} key={item["value"]}>
                    {" "}
                    {item["label"]}{" "}
                  </option>
                );
              } else {
                return null;
              }
            })}
          </Form.Select>
        </Col>
      </Form.Group>
    </Form>
  );
};
