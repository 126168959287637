import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  useCreatePropositionMutation,
  useUpdatePropositionMutation,
} from "store/features/propositions";
import { TypeProposition } from "types/Propositions";

export const ButtonSendProposition = ({
  propositions,
  id_user,
  on_end,
}: {
  propositions: TypeProposition[];
  id_user: string;
  on_end: () => void;
}) => {
  let navigate = useNavigate();
  const [createProposition] = useCreatePropositionMutation();
  const [updateProposition] = useUpdatePropositionMutation();

  return (
    <Button
      onClick={() => {
        propositions?.forEach((proposition) => {
          let proposition_to_send = { ...proposition };

          if (!proposition["submitted_to"].includes(id_user)) {
            let new_submitted_to = [...proposition["submitted_to"], id_user];
            proposition_to_send["submitted_to"] = new_submitted_to;
          }
          console.log("proposition_to_send", proposition_to_send);
          createProposition(proposition_to_send);
        });
        on_end();
      }}
    >
      Valider mes réponses
    </Button>
  );
};
