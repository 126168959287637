import { TypeProposition } from "types/Propositions";

// should be in a tools file somewhere
export const is_my_proposal = (
  prop: TypeProposition,
  user_id: string
): boolean => {
  let all_opinion = prop["validated_by"].concat(
    prop["possible_by"],
    prop["rejected_by"]
  );
  if (all_opinion.includes(user_id) && all_opinion.length === 1) {
    return true;
  }
  return false;
};
