import { useEffect, useState } from "react";

const config_google_api = {
  clientId: process.env.REACT_APP_CLIENT_ID_GOOGLE as string,
  apiKey: process.env.REACT_APP_API_KEY_GOOGLE as string,
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

export const useGoogleGapi = () => {
  useEffect(() => {
    const SCOPE = config_google_api["scope"];
    const handleClientLoad = () => window.gapi.load("client", initClient);

    const initClient = () => {
      window.gapi.client.init({
        apiKey: config_google_api["apiKey"],
        discoveryDocs: config_google_api["discoveryDocs"],
      });
    };

    const script = document.createElement("script");

    script.src = "https://apis.google.com/js/api.js";
    script.async = true;
    script.defer = true;
    script.onload = handleClientLoad;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return;
};

export const useGoogleGis = () => {
  const [googleToken, setGoogleToken] = useState();
  console.log("googleToken in GoogleGis", googleToken);
  console.log("config_google_api", config_google_api);

  useEffect(() => {
    const SCOPE = config_google_api["scope"];
    const handleClientLoad = () => {
      // setGoogleToken(
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: config_google_api["clientId"],
        scope: SCOPE,
        response_type: "token",
        prompt: "consent",
        access_type: "offline",
        callback: (tokenResponse: any) => {
          console.log("tokenResponse", tokenResponse);
        }, // defined later
      });
      console.log("googleToken in  apres le useEffect", googleToken);
      setGoogleToken(client);
    };
    const script = document.createElement("script");

    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = handleClientLoad;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return googleToken;
};
