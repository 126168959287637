import { useEffect, useState } from "react";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";

import { createId } from "api/createId";
import GoogleImport from "modules/GoogleSynch";
import OutlookImport from "modules/OutlookSynch";
import { CalendarScreenCapture } from "modules/ScreenCaptureAgenda";
import {
  useCreateEventMutation,
  useRemoveSourceMutation,
} from "store/features/events";
import { TypeEvent } from "types/DaTimeEvents";

const googleItemToFullCalendar = (
  item: any,
  user_id: string | null,
  id_calendar: string
): TypeEvent => {
  let url = item.htmlLink || null;
  return {
    start: item.start.dateTime || item.start.date, // try timed. will fall back to all-day
    end: item.end.dateTime || item.end.date, // same
    id: createId(), // should be related to item.id,
    allDay: false,
    title: item.summary,
    // id_meeting: [],
    SharedWith: [],
    lieu: item.location,
    user_id: user_id ? user_id : "",
    transport: null,
    rappel: null,
    priority: 1,
    source_type: "google",
    source_id: id_calendar,
    rrule: item.recurrence ? item.recurrence[0] : null,
    // extendedProps: (item.extendedProperties || {}).shared || {},
  };
};

export const ScreenCapture = () => {
  const [modalOn, setModalOn] = useState(false);
  const handleClose = () => setModalOn(false);

  return (
    <>
      <br />
      <Modal
        backdrop="static"
        keyboard={true}
        show={modalOn}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <h2> Faites une capture et contrôler le résultat </h2>
        </Modal.Header>
        <Modal.Body>
          <CalendarScreenCapture></CalendarScreenCapture>
        </Modal.Body>
      </Modal>
      <Button variant="info" onClick={() => setModalOn(true)}>
        {" "}
        Tester la capture d'écran{" "}
      </Button>
    </>
  );
};

export const ImportAgenda = ({
  user_id,
  setEvents,
}: {
  user_id: string | null;
  setEvents: (events: TypeEvent[]) => void;
}) => {
  const [googleReponse, setGoogleReponse] = useState<any>(null);

  const [createEvent] = useCreateEventMutation();
  const [removeSource] = useRemoveSourceMutation();

  useEffect(() => {
    if (googleReponse) {
      const events_from_google = googleReponse.result.items;
      const id_calendar = googleReponse.result.etag;
      removeSource({ source_type: "google", source_id: id_calendar });
      // on pourrait traiter ensemble les recurring et les non recurring
      // mais on continue comme ça pour résoudre le bug sur le timezone

      if (user_id) {
        events_from_google.forEach((item: any) => {
          let fc_item = googleItemToFullCalendar(item, user_id, id_calendar);
          createEvent(fc_item);
        });
      }

      if (!user_id) {
        const events_fc = events_from_google.map((item: any) => {
          return googleItemToFullCalendar(item, user_id, id_calendar);
        });
        setEvents(events_fc);
      }

      // const non_recurring_events_from_google = events_from_google.filter(
      //   (item: any) => !("recurrence" in item)
      // );
      // const recurring_events_from_google = events_from_google.filter(
      //   (item: any) => "recurrence" in item
      // );

      // non_recurring_events_from_google.forEach((item: any) => {
      //   let fc_item = googleItemToFullCalendar(item, user_id, id_calendar);
      //   createEvent(fc_item);
      // });

      // recurring_events_from_google.forEach((item: any) => {
      //   let fc_item = googleItemToFullCalendar(item, user_id, id_calendar);
      //   createEvent(fc_item);
      // });
    }
  }, [createEvent, googleReponse, removeSource, user_id]);

  return (
    <>
      <Row>
        <Col
          className="align-items-center"
          sm={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Accordion>
            <Accordion.Item eventKey="meth_1">
              <Accordion.Header>
                Importez vos agendas existants et laisser DaTime faire le
                travail à votre place.
              </Accordion.Header>
              <Accordion.Body>
                Le contenu des événements n'est pas lu par DaTime. Rien n'est
                conservé à ce stade et quand vous aurez fermé cette page, on se
                souviendra uniquement des propositions.
                <Accordion style={{ margin: "5px", padding: "5px" }}>
                  <Accordion.Item eventKey="google">
                    <Accordion.Header>Google</Accordion.Header>
                    <Accordion.Body>
                      En général, les autorisations sont données par défaut pour
                      les comptes google, si ce n'est pas le cas, vous pouvez
                      nous transmettre dans l'onglet ses coordonnées et nous le
                      contacterons.
                      <GoogleImport setReponse={setGoogleReponse} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="outlook">
                    <Accordion.Header>Outlook</Accordion.Header>
                    <Accordion.Body>
                      En général, les autorisations sont données par défaut pour
                      les comptes google, si ce n'est pas le cas, vous pouvez
                      nous transmettre dans l'onglet ses coordonnées et nous le
                      contacterons.
                      {/* faire comme pour google import  */}
                      <OutlookImport user_id={user_id ? user_id : ""} />
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="screen-catpure">
                    <Accordion.Header>Capture d'écran</Accordion.Header>
                    <Accordion.Body>
                      A utiliser en dernier recours
                      <ScreenCapture />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                Si ça ne fonctionne pas voir notre FAQ pour les problèmes
                courrants
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default ImportAgenda;
