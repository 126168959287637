import { createId } from "api/createId";
import { mail_Datime } from "data/mails_types";
import { TextEmailModal } from "modules/textEmailModal";
import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useGetMeetingByIdQuery } from "store/features/meetings";
import {
  useCreateParticipantsMutation,
  useDeleteParticipantsMutation,
  useGetParticipantsOfMeetingQuery,
  useUpdateParticipantsMutation,
} from "store/features/participants";
import { useGetUserQuery } from "store/features/user";
import { User } from "store/SessionReducer";
import { InvitedMeetingType, StatusInvited } from "types/Dater";
import { MailType } from "types/Mail";

const originURL = process.env.REACT_APP_URL_Origin as string;

type TableParticipantsInvitationProps = {
  id_meeting: string | undefined;
  connectedUser: User | undefined;
  duration_global: number;
};

type RowInvitedProps = {
  invit: InvitedMeetingType;
};

const ChoseModeContact = ({ invit }: RowInvitedProps) => {
  let navigate = useNavigate();

  const { data: user } = useGetUserQuery();
  const [modalEmailOn, setModalEmailOn] = useState(false);
  const [updateParcicipant] = useUpdateParticipantsMutation();
  const { data: meetingPage } = useGetMeetingByIdQuery(invit["id_meeting"]);

  // const [organisator, setOrganisator] = useState("oragnisator@email.com");
  // const { data: participants } = useGetParticipantsOfMeetingQuery(
  //   invit["id_meeting"]
  // );
  // useEffect(() => {
  //   if (meetingPage && participants) {
  //     const organisators = participants.filter(
  //       (part) => part.organisator === true
  //     );
  //     if (organisators.length === 1) {
  //     }
  //     setOrganisator("new_org");
  //   }
  // }, [meetingPage, participants]);

  const handleCloseEmail = () => {
    setModalEmailOn(false);
  };

  const [body, setBody] = useState(<></>);

  const personnal_link =
    originURL + "/invitation/" + invit["id_meeting"] + "/" + invit["id_person"];

  const datimer_link =
    originURL + "/invitation/" + invit["id_meeting"] + "/" + invit["id_person"];

  const [modalEmailData, setModalEmailData] = useState<MailType>({
    to: invit["mail"] ? invit["mail"] : "Undefined",
    subject: `Organiser "${
      meetingPage ? meetingPage["object"] : "une réunion"
    }" avec DaTime`,
    body: body,
    from: user ? user["email"] : "no-reply@datime.pro",
  });

  useEffect(() => {
    setModalEmailData({
      to: invit["mail"] ? invit["mail"] : "Undefined",
      subject: `Organiser "${
        meetingPage ? meetingPage["object"] : "une réunion"
      }" avec DaTime`,
      body: body,
      from: user ? user["email"] : "no-reply@datime.pro",
    });
  }, [invit, meetingPage, body, user]);

  return (
    <>
      <TextEmailModal
        modalOn={modalEmailOn}
        toRemoveOn={false}
        handleClose={handleCloseEmail}
        mail={modalEmailData}
        children={modalEmailData["body"]}
      />
      <ButtonGroup>
        <DropdownButton
          key={"2" + invit["id"]}
          id={`modeContact-2` + invit["id"]}
          title="Par moi-même"
          drop="end"
          style={{ padding: "5px" }}
        >
          <Dropdown.Item eventKey="myself">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(personnal_link);
              }}
            >
              Copier le lien personnel à envoyer
            </Button>
          </Dropdown.Item>
        </DropdownButton>

        <DropdownButton
          // as={ButtonGroup}
          title="Par Mail"
          id="bg-nested-dropdown"
          style={{ padding: "5px" }}
        >
          <Dropdown.Item eventKey="2">
            {" "}
            <Button
              key={invit["id"]}
              // disabled={!isNew}
              onClick={() => {
                setBody(
                  mail_Datime({
                    author: user
                      ? user["email"]
                        ? user["email"]
                        : "une personne"
                      : "une personne",
                    object: meetingPage ? meetingPage["object"] : "une réunion",
                    lien_dateTime: personnal_link,
                    lien_deja_client: datimer_link,
                  })
                );
                setModalEmailOn(true);
                updateParcicipant({ ...invit, mode_contact: "datime" });
              }}
            >
              {" "}
              Mail Type DaTime{" "}
            </Button>
          </Dropdown.Item>
          <Dropdown.Item eventKey="1">
            <Button
              disabled
              key={invit["id"]}
              onClick={() => {
                setBody(<h1>mail_type_1</h1>);
                setModalEmailOn(true);
                updateParcicipant({ ...invit, mode_contact: "mail_type_1" });
              }}
            >
              {" "}
              Mail Type 1{" "}
            </Button>
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            {" "}
            <Button
              disabled
              key={invit["id"]}
              // disabled={!isNew}
              onClick={() => {
                setBody(<h1>mail_type_2</h1>);
                updateParcicipant({ ...invit, mode_contact: "mail_type_2" });
                setModalEmailOn(true);
              }}
            >
              {" "}
              Mail Type 2{" "}
            </Button>
          </Dropdown.Item>
        </DropdownButton>
      </ButtonGroup>
      <div>
        <OverlayTrigger
          overlay={
            <Tooltip id={"tooltip-mode-contact"}>
              {
                <p>
                  Utilisez cette option si votre interlocuteur vous communique
                  ses disponibilités en dehors de DaTime.
                </p>
              }
            </Tooltip>
          }
        >
          <Button
            variant="secondary"
            style={{ padding: "5px" }}
            onClick={() => {
              navigate(
                "/invitation/" + invit["id_meeting"] + "/" + invit["id_person"]
              );
            }}
          >
            Remplir à sa place
          </Button>
        </OverlayTrigger>
      </div>
    </>
  );
};

const RowInvited = ({ invit }: RowInvitedProps) => {
  let navigate = useNavigate();
  const [modifying, setModifying] = useState(false);

  const [deleteParticipant] = useDeleteParticipantsMutation();
  const [updateParcicipant] = useUpdateParticipantsMutation();

  const jour_rappel =
    invit["status"] === "invited" && invit["invited_date"]
      ? Math.floor(
          Math.abs(
            new Date().getTime() - new Date(invit["invited_date"]).getTime()
          ) /
            (1000 * 60 * 60 * 24)
        )
      : 0;
  console.log("a_rappeler", invit["invited_date"], jour_rappel);

  if (invit["organisator"]) {
    return (
      <tr key={invit["id"]}>
        <td>{invit["name"]}</td>

        <td>{invit["mail"]}</td>
        <td>{invit["mandatory"] ? "Oui" : "Non"}</td>
        <td>Non nécessaire</td>
        <td>
          <Button
            onClick={() => {
              navigate("/availabilities/" + invit["id_meeting"]);
            }}
          >
            Voir les propositions
          </Button>
        </td>
        <td>Non nécessaire</td>
        <td>
          <button
            className="btn btn-outline-danger"
            onClick={() => {
              deleteParticipant(invit["id"]);
            }}
          >
            x
          </button>
        </td>
      </tr>
    );
  }

  return (
    <tr key={invit["id"]}>
      <td>
        {modifying ? (
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder={
                  invit["name"] ? invit["name"] : invit["mail"]?.split("@")[0]
                }
                onBlur={(evnt) =>
                  updateParcicipant({ ...invit, name: evnt.target.value })
                }
              />
            </Form.Group>
          </Form>
        ) : (
          invit["name"]
        )}
      </td>
      <td>
        {modifying ? (
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                placeholder={invit["mail"] ? invit["mail"] : "name@example.com"}
                onBlur={(evnt) =>
                  updateParcicipant({ ...invit, mail: evnt.target.value })
                }
              />
            </Form.Group>
          </Form>
        ) : (
          invit["mail"]
        )}
      </td>
      <td>
        {modifying ? (
          <>
            {invit["mandatory"] ? "Oui" : "Non"}
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                // checked={invit["mandatory"]}
                onChange={(evnt) => {
                  updateParcicipant({
                    ...invit,
                    mandatory: evnt.target.checked,
                  });
                }}
              />
            </Form>
          </>
        ) : invit["mandatory"] ? (
          "Oui"
        ) : (
          "Non"
        )}
      </td>

      <td>
        {" "}
        <ChoseModeContact
          invit={invit}
          key={invit["id"]}
        ></ChoseModeContact>{" "}
      </td>
      <td
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          {invit["status"] !== "to_invite"
            ? invit["invited_date"]
              ? "Oui le " + invit["invited_date"]
              : "Oui"
            : "Non"}
        </div>
        {jour_rappel > 5 ? (
          <div
            style={{
              background: "red",
              width: "70%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              alignSelf: "center",
            }}
          >
            {jour_rappel} jours se sont écoulé depuis l'invitation, DaTime vous
            suggère une relance.
          </div>
        ) : null}

        {modifying ? (
          <Form>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              checked={invit["status"] === ("invited" as StatusInvited)}
              onChange={(evnt) => {
                const new_statut =
                  invit["status"] === "to_invite" ? "invited" : "to_invite";
                updateParcicipant({
                  ...invit,
                  invited_date: new Date(),
                  status: new_statut as StatusInvited,
                });
              }}
            />
          </Form>
        ) : null}
      </td>
      <td>
        {invit["status"] === "has_answered"
          ? invit["answer_date"]
            ? "Oui le " + invit["invited_date"]
            : "Oui"
          : "Non"}
      </td>
      {/* <td>{invit["status"] !== "to_invite" ? "Oui" : "Non"}</td> */}
      <td>
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            setModifying(!modifying);
          }}
        >
          Modifier
        </button>
        <button
          className="btn btn-outline-danger"
          onClick={() => {
            deleteParticipant(invit["id"]);
          }}
        >
          x
        </button>
      </td>
    </tr>
  );
};

export const TableParticipantsInvitation = ({
  id_meeting,
  connectedUser,
  duration_global,
}: TableParticipantsInvitationProps) => {
  const { data: participants } = useGetParticipantsOfMeetingQuery(id_meeting);
  const [createParticipant] = useCreateParticipantsMutation();

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th style={{ width: "10%" }}>Nom</th>
          <th style={{ width: "10%" }}>Mail</th>
          {/* <th>Téléphone</th> */}
          <th style={{ width: "5%" }}>Présence impérative</th>
          <OverlayTrigger
            overlay={
              <Tooltip id={"tooltip-mode-contact"}>
                {
                  <p>
                    Si vous choisissez d'envoyez par vous même, vous trouverez
                    les liens dès que la réunion sera créée.
                  </p>
                }
              </Tooltip>
            }
          >
            <th>
              Contacter {"   "}
              <InfoCircle />
            </th>
          </OverlayTrigger>

          <th>A été contacté</th>
          <th>A répondu</th>
          {/* <th>
            Suggestion <br /> DaTime{" "}
          </th> */}
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <>
          {participants
            ? participants.map((item, idx) => {
                return <RowInvited invit={item} key={item["id"]}></RowInvited>;
              })
            : null}
          <tr>
            <td colSpan={100}>
              <button
                className="btn btn-outline-success"
                onClick={() => {
                  if (connectedUser && id_meeting) {
                    const rowsInput: InvitedMeetingType = {
                      id: createId(),
                      id_person: createId(),
                      id_meeting: id_meeting,
                      added_by: connectedUser.id,
                      name: "",
                      mail: "",
                      organisator: false,
                      status: "to_invite",
                      mode_contact: "datime",
                      mandatory: true,
                      nb_asked: 0,
                      my_duration: 60,
                      my_object: "",
                    };
                    createParticipant(rowsInput);
                  }
                }}
              >
                Ajouter un participant
              </button>
            </td>
          </tr>
        </>
      </tbody>
    </Table>
  );
};
