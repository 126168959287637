import { Button } from "react-bootstrap";

import { useGoogleGapi, useGoogleGis } from "./useGoogle";

import { addTwoMonthsToDate } from "tools/dates";

// inspiration : https://github.com/fullcalendar/fullcalendar/tree/main/packages/google-calendar/src
// inspiration 2 : la doc de google developper

const GoogleImport = ({
  setReponse,
}: {
  setReponse: (reponse: any) => void;
}) => {
  useGoogleGapi();
  const token = useGoogleGis() as any;

  const styleElement = {
    backgroundColor: "rgba(7, 124, 240, 0.1)",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleAuthClick = () => {
    const gapi = window.gapi;
    token.callback = async (resp: any) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      console.log("token iic", resp);
      const calendarId = "primary";
      try {
        const response = await gapi.client.calendar.events.list({
          calendarId: calendarId,
          timeMin: new Date().toISOString(),
          timeMax: addTwoMonthsToDate().toISOString(),
          showDeleted: false,
          singleEvents: false,
          showHiddenInvitations: true,
          maxResults: 100,
          timeZone: "UTC",
          // 'orderBy': 'startTime', # impossible quand on prend les recurrings events
        });
        setReponse(response);
      } catch (err) {
        console.log("Error in requesting Google Calendar", err);
      }
    };

    if (gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      token.requestAccessToken({ prompt: "consent", access_type: "offline" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      token.requestAccessToken({ prompt: "" });
    }
    return "";
  };

  return (
    <>
      {/* <script src='https://apis.google.com/js/api.js' onLoad={gapiLoaded()} async={true} > </script> */}
      <div style={{ ...styleElement, borderRadius: "25px" }}>
        <Button onClick={handleAuthClick}> Importer un agenda Google</Button>
        {/* <p>Ce n'est pas forcément l'agenda associé à votre compte supabase</p> */}
      </div>
    </>
  );
};

export default GoogleImport;
