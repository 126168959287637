import {
  DateSelectArg,
  DatesSetArg,
  EventAddArg,
  EventApi,
  EventChangeArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import { useSelector } from "react-redux";
// import { EventImpl } from '@fullcalendar/core/internal'

import esLocale from "@fullcalendar/core/locales/es";
import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import iCalendarPlugin from "@fullcalendar/icalendar";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import rrulePlugin from "@fullcalendar/rrule";
import timeGridPlugin from "@fullcalendar/timegrid";

import {
  EmojiFrown,
  EmojiSmile,
  InfoCircle,
  XSquare,
} from "react-bootstrap-icons";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";

import {
  BusinesHoursSelector,
  MaxTimeSelector,
  MinTimeSelector,
  WeekendSelector,
} from "store/selectors";
import { TypeProposition } from "types/Propositions";

import {
  from_date_to_Proposition,
  from_EventApi_to_Proposition,
} from "format/change_format";
import { Button, OverlayTrigger } from "react-bootstrap";

import { EventImpl } from "@fullcalendar/core/internal";
import { postIsFree } from "api/postIsFree";
import { useGetMeetingByIdQuery } from "store/features/meetings";
import { convert_date_to_utc_without_second } from "tools/convert_to_db";
import { proposition_with_new_opinion } from "tools/propositions/change_user_opinion";
import { is_my_proposal } from "tools/propositions/is_my_proposal";
import { TypeEvent } from "types/DaTimeEvents";
// let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
const LOCALES = [esLocale, frLocale];

type InvitedCalendarProps = {
  // id_meeting: string;
  id_user: string;
  id_meeting: string;
  proposMeeting: TypeProposition[];
  setProposMeeting: (propositions: TypeProposition[]) => void;
  events_sup: TypeEvent[];
};

export const InvitedCalendar = ({
  id_user,
  id_meeting,
  proposMeeting,
  setProposMeeting,
  events_sup,
}: InvitedCalendarProps) => {
  const weekendsVisible = useSelector(WeekendSelector);

  const { data: meetingPage } = useGetMeetingByIdQuery(id_meeting);

  const bh = useSelector(BusinesHoursSelector);
  const slotMinTime = useSelector(MinTimeSelector);
  const slotMaxTime = useSelector(MaxTimeSelector);
  // const default_location = null; // useSelector(DefaultLocationSelector)
  // const default_rappel = null; // useSelector(DefaultRappelSelector)
  // const default_transport = null; // useSelector(DefaultTransportSelector)

  const handleDates = (rangeInfo: DatesSetArg) => {}; // pas d'appel à la base

  const handlePropositionAdd = (addInfo: EventAddArg) => {
    console.log("handlePropositionAdd");
    const temp_proposition = from_EventApi_to_Proposition(
      addInfo.event,
      id_user
    );
    // createProposition(temp_proposition);
    setProposMeeting([...proposMeeting, temp_proposition]);
  };

  const handlePropositionChange = (changeInfo: EventChangeArg) => {
    console.log("handlePropositionChange");
    let temp_proposition = from_EventApi_to_Proposition(
      changeInfo.event,
      id_user
    );
    const new_proposition = {
      ...temp_proposition,
      title: changeInfo.event.title,
    };
    // updateProposition(temp_proposition);
    const new_propositions = proposMeeting.filter(
      (prop) => prop.id !== changeInfo.event.id
    );
    setProposMeeting([...new_propositions, new_proposition]);
  };

  const handlePropositionClick = (clickInfo: EventClickArg) => {
    let proposition = from_EventApi_to_Proposition(clickInfo.event, id_user);

    const new_proposition = proposition_with_new_opinion(
      proposition,
      id_user,
      meetingPage?.object
    );
    // updateProposition(new_proposition);
    const new_propositions = proposMeeting.filter(
      (prop) => prop.id !== clickInfo.event.id
    );
    setProposMeeting([...new_propositions, new_proposition]);
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    if (id_user && id_meeting) {
      let event_to_add = from_date_to_Proposition(
        selectInfo,
        id_user,
        id_meeting
      );
      let new_proposition = {
        ...event_to_add,
        title: "Nouvelle proposition",
      };
      // createProposition(new_proposition);
      setProposMeeting([...proposMeeting, new_proposition]);
    }
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
  };

  const handleEvents = (local_events: EventApi[]) => {
    // console.log("handleEventsd", local_events);
  };

  const dropEvents = () => {
    console.log("dropEvent");
  };

  const handleClose = () => null;

  const ButtonIsFree = ({ event }: { event: EventImpl }) => {
    return (
      <Button
        size="sm"
        onClick={() => {
          postIsFree(id_meeting, [
            {
              start: convert_date_to_utc_without_second(event.startStr),
              end: convert_date_to_utc_without_second(event.endStr),
            },
          ]).then((isFree) => {
            const asked_proposition = proposMeeting.filter(
              (prop) => prop.id === event.id
            )[0];
            const other_propositions = proposMeeting.filter(
              (prop) => prop.id !== event.id
            );
            if (isFree[0]) {
              let new_proposition = {
                ...asked_proposition,
                title: "Nouvelle proposition ok pour DaTime",
                is_new: false,
              };
              setProposMeeting([...other_propositions, new_proposition]);
            } else {
              let new_proposition = {
                ...asked_proposition,
                title: "Proposition rejetée via DaTime",
                availability: 0,
                is_new: false,
              };
              setProposMeeting([...other_propositions, new_proposition]);
            }
          });
        }}
      >
        Tester la proposition
      </Button>
    );
  };

  const renderPropositionContent = (eventContent: EventContentArg) => {
    let proposition = proposMeeting.filter(
      (prop) => prop.id === eventContent.event.id
    )[0];
    return (
      <Container>
        <Row>
          <Col sm={9}>
            <b>{eventContent.timeText}</b>
          </Col>
          <Col sm={1}>
            <OverlayTrigger
              overlay={
                <Tooltip id={"tooltip-" + eventContent.event.id}>
                  {
                    <ul style={{ textAlign: "left" }}>
                      Cliquer pour changer la couleur :<li> vert : ok </li>
                      <li> orange : possible mais pas souhaité </li>
                      <li> rouge : impossible </li>
                    </ul>
                  }
                </Tooltip>
              }
            >
              <InfoCircle></InfoCircle>
            </OverlayTrigger>
          </Col>
          <Col sm={1}>
            <XSquare
              onClick={() => {
                const new_propositions = proposMeeting.filter(
                  (prop) => prop.id !== eventContent.event.id
                );
                setProposMeeting([...new_propositions]);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <i>{eventContent.event.title}</i>
          </Col>
        </Row>
        <Row>
          {proposition &&
          is_my_proposal(proposition, id_user) &&
          !proposition["is_new"] ? (
            proposition["availability"] > 0 ? (
              <EmojiSmile size={20} />
            ) : (
              <EmojiFrown size={20} />
            )
          ) : null}
        </Row>
        {proposition && proposition["is_new"] ? (
          <ButtonIsFree event={eventContent.event}></ButtonIsFree>
        ) : null}
      </Container>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <FullCalendar
            plugins={[
              interactionPlugin,
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              iCalendarPlugin,
              rrulePlugin,
            ]}
            headerToolbar={
              {
                // left: 'prev,next today',
                // center: 'title',
                // right: 'timeGridDay,timeGridWeek,dayGridMonth'
              }
            }
            businessHours={bh}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            contentHeight={"auto"}
            titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
            locale={"fr"}
            locales={LOCALES}
            initialView="timeGridWeek"
            editable={true}
            droppable={true}
            selectable={true}
            allDaySlot={false}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            eventSources={[
              {
                events: proposMeeting?.filter(
                  (item) => item.availability === 1
                ),
                color: "green", // an option!
                textColor: "black", // an option!
              },
              {
                events: proposMeeting?.filter(
                  (item) => item.availability === 0
                ),
                color: "red",
              },
              {
                events: proposMeeting?.filter(
                  (item) => item.availability > 0 && item.availability < 1
                ),
                color: "orange", // rgb(255,150,77,0.7)
              },
              {
                events: events_sup,
                color: "red", // an option!
                textColor: "black", // an option!
                editable: false,
              },
              // any other event sources...
            ]}
            datesSet={handleDates}
            select={handleDateSelect}
            eventAdd={handlePropositionAdd}
            eventChange={handlePropositionChange} // called for drag-n-drop/resize
            eventContent={renderPropositionContent} // custom render function
            eventClick={handlePropositionClick}
            eventDrop={dropEvents}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          />
        </Col>
      </Row>
    </Container>
  );
};
