import { TypeProposition } from "types/Propositions";
import { title_of_proposition } from "./set_proposition_title";

const change_availabiliy = (availability: number) => {
  let result = availability >= 0.5 ? availability - 0.5 : 1;
  return result;
};

// on pourrait faire en deux temps : d'abord on remove et ensuite on ajoute
const set_user_opinion = (
  id_user: string,
  proposition: TypeProposition,
  opinion: "validated" | "possible" | "rejected"
): [string[], string[], string[]] => {
  // remove previous opinion
  const new_values = ["validated_by", "possible_by", "rejected_by"].map(
    (item) => {
      return (proposition[item] as string[]).filter(
        (id: string) => id !== id_user
      );
    }
  ) as [string[], string[], string[]];
  new_values[0] =
    opinion === "validated" ? [...new_values[0], id_user] : new_values[0];
  new_values[1] =
    opinion === "possible" ? [...new_values[1], id_user] : new_values[1];
  new_values[2] =
    opinion === "rejected" ? [...new_values[2], id_user] : new_values[2];

  return new_values;
};

const shift_user_opinion = (
  id_user: string,
  proposition: TypeProposition
): [string[], string[], string[]] => {
  if (proposition["validated_by"].includes(id_user)) {
    const possible_by = [...proposition["possible_by"], id_user];
    return [
      proposition["validated_by"].filter((item) => item !== id_user),
      possible_by,
      proposition["rejected_by"],
    ];
  } else if (proposition["possible_by"].includes(id_user)) {
    const rejected_by = [...proposition["rejected_by"], id_user];
    return [
      proposition["validated_by"],
      proposition["possible_by"].filter((item) => item !== id_user),
      rejected_by,
    ];
  } else if (proposition["rejected_by"].includes(id_user)) {
    const validated_by = [...proposition["validated_by"], id_user];
    return [
      validated_by,
      proposition["possible_by"],
      proposition["rejected_by"].filter((item) => item !== id_user),
    ];
  } else {
    return [[], [], []];
  }
};

export const proposition_with_new_opinion = (
  proposition: TypeProposition,
  id_user: string,
  meeting_name: string | undefined,
  opinion: "validated" | "possible" | "rejected" | "shift" = "shift"
): TypeProposition => {
  let new_availability = 1;
  let new_ok: string[], new_possible: string[], new_rejected: string[];
  if (opinion === "shift") {
    [new_ok, new_possible, new_rejected] = shift_user_opinion(
      id_user,
      proposition
    );
    new_availability = change_availabiliy(proposition.availability);
  } else {
    [new_ok, new_possible, new_rejected] = set_user_opinion(
      id_user,
      proposition,
      opinion
    );
    new_availability =
      opinion === "validated" ? 1 : opinion === "possible" ? 0.5 : 0;
  }

  let new_title = title_of_proposition(new_availability, meeting_name);
  let new_proposition = {
    ...proposition,
    availability: new_availability,
    title: new_title,
    validated_by: new_ok,
    possible_by: new_possible,
    rejected_by: new_rejected,
  };
  return new_proposition;
};
