import {
  DateSelectArg,
  DatesSetArg,
  EventAddArg,
  EventApi,
  EventChangeArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import { useSelector } from "react-redux";
// import { EventImpl } from '@fullcalendar/core/internal'

import esLocale from "@fullcalendar/core/locales/es";
import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import iCalendarPlugin from "@fullcalendar/icalendar";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import rrulePlugin from "@fullcalendar/rrule";
import timeGridPlugin from "@fullcalendar/timegrid";

import { InfoCircle, XSquare } from "react-bootstrap-icons";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";

import {
  BusinesHoursSelector,
  MaxTimeSelector,
  MinTimeSelector,
  WeekendSelector,
} from "store/selectors";
import { TypeProposition } from "types/Propositions";

import { OverlayTrigger } from "react-bootstrap";

import {
  from_EventApi_to_Proposition,
  from_date_to_Proposition,
} from "format/change_format";
import { useGetEventsOfUserQuery } from "store/features/events";
import { useGetMyMeetingByIDQuery } from "store/features/participants";
import { proposition_with_new_opinion } from "tools/propositions/change_user_opinion";
const LOCALES = [esLocale, frLocale];

type CalendarPropositionProps = {
  id_meeting: string;
  id_user: string;
  proposMeeting: TypeProposition[];
  setProposMeeting: (propositions: TypeProposition[]) => void;
  proposOtherMeeting: TypeProposition[];
};

export const CalendarProposition = ({
  id_meeting,
  id_user,
  proposMeeting,
  setProposMeeting,
  proposOtherMeeting,
}: CalendarPropositionProps) => {
  const weekendsVisible = useSelector(WeekendSelector);

  const { data: myMeeting } = useGetMyMeetingByIDQuery({
    id_meeting,
    id_person: id_user,
  });

  const { data: my_events } = useGetEventsOfUserQuery(id_user);

  console.log("proposMeeting", proposMeeting);

  const bh = useSelector(BusinesHoursSelector);
  const slotMinTime = useSelector(MinTimeSelector);
  const slotMaxTime = useSelector(MaxTimeSelector);

  // les actions que l'on va utiliser ici pour d'abord changer la database puis pour changer le state
  const handleDates = (rangeInfo: DatesSetArg) => {}; // pas d'appel à la base

  const handlePropositionAdd = (addInfo: EventAddArg) => {
    if (id_user) {
      const temp_proposition = from_EventApi_to_Proposition(
        addInfo.event,
        id_user
      );
      // createProposition(temp_proposition);
      setProposMeeting([...proposMeeting, temp_proposition]);
    }
  };

  const handlePropositionChange = (changeInfo: EventChangeArg) => {
    if (id_user) {
      let temp_proposition = from_EventApi_to_Proposition(
        changeInfo.event,
        id_user
      );
      const new_proposition = {
        ...temp_proposition,
        title: changeInfo.event.title,
      };
      // updateProposition(temp_proposition);
      const new_propositions = proposMeeting.filter(
        (prop) => prop.id !== changeInfo.event.id
      );
      setProposMeeting([...new_propositions, new_proposition]);
    }
  };

  const handlePropositionClick = (clickInfo: EventClickArg) => {
    if (id_user) {
      let proposition = from_EventApi_to_Proposition(clickInfo.event, id_user);
      const new_proposition = proposition_with_new_opinion(
        proposition,
        id_user,
        myMeeting ? myMeeting["my_object"] : undefined
      );

      const new_propositions = proposMeeting.filter(
        (prop) => prop.id !== clickInfo.event.id
      );
      setProposMeeting([...new_propositions, new_proposition]);
    }
  };

  const renderPropositionContent = (eventContent: EventContentArg) => {
    // if (eventContent.event.extendedProps?.id_meeting === id_meeting){
    //   return (
    //     <>
    //       Déplacer !
    //     </>
    //   )
    // }
    return (
      <Container>
        <Row>
          <Col sm={9}>
            <b>{eventContent.timeText}</b>
          </Col>
          <Col sm={1}>
            <OverlayTrigger
              overlay={
                <Tooltip id={"tooltip-" + eventContent.event.id}>
                  {
                    <ul style={{ textAlign: "left" }}>
                      Cliquer pour changer la couleur :<li> vert : ok </li>
                      <li> orange : possible mais pas souhaité </li>
                      <li> rouge : impossible </li>
                    </ul>
                  }
                </Tooltip>
              }
            >
              <InfoCircle></InfoCircle>
            </OverlayTrigger>
          </Col>
          <Col sm={1}>
            <XSquare
              onClick={() => {
                const new_propositions = proposMeeting.filter(
                  (prop) => prop.id !== eventContent.event.id
                );
                setProposMeeting([...new_propositions]);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <i>{eventContent.event.title}</i>
          </Col>
        </Row>
      </Container>
    );
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    if (id_user && id_meeting) {
      let event_to_add = from_date_to_Proposition(
        selectInfo,
        id_user,
        id_meeting
      );
      let new_proposition = {
        ...event_to_add,
        title: "Nouvelle proposition",
      };
      // createProposition(new_proposition);
      setProposMeeting([...proposMeeting, new_proposition]);
    }
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
  };

  const handleEvents = (local_events: EventApi[]) => {
    // console.log('handleEventsd', local_events)
  };

  const dropEvents = () => {
    console.log("dropEvent");
  };

  return (
    <Container>
      <Row>
        <Col>
          <FullCalendar
            plugins={[
              interactionPlugin,
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              iCalendarPlugin,
              rrulePlugin,
            ]}
            headerToolbar={
              {
                // left: 'prev,next today',
                // center: 'title',
                // right: 'timeGridDay,timeGridWeek,dayGridMonth'
              }
            }
            businessHours={bh}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            contentHeight={"auto"}
            titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
            locale={"fr"}
            locales={LOCALES}
            initialView="timeGridWeek"
            editable={true}
            droppable={true}
            selectable={true}
            allDaySlot={false}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            eventSources={[
              // your event source
              {
                events: proposMeeting?.filter(
                  (item) => item.availability === 1
                ),
                color: "green", // an option!
                textColor: "black", // an option!
                // eventDataTransform: (event) => {
                //   console.log('in eventDataTransform', event)
                //   return ( {...event, title : 'data transfmated'} )
                // }
              },
              {
                events: proposMeeting?.filter(
                  (item) => item.availability === 0
                ),
                color: "red",
              },
              {
                events: proposMeeting?.filter(
                  (item) => item.availability > 0 && item.availability < 1
                ),
                color: "orange", // rgb(255,150,77,0.7)
              },
              {
                events: proposOtherMeeting?.filter(
                  (item) => item.availability === 1
                ),
                color: "rgb(30,179,0, 0.4)",
                editable: false,
              },
              {
                events: proposOtherMeeting?.filter(
                  (item) => item.availability === 0
                ),
                color: "rgb(255,25,25, 0.4)",
                editable: false,
              },
              {
                events: proposOtherMeeting?.filter(
                  (item) => item.availability > 0 && item.availability < 1
                ),
                color: "rgb(204,136,0, 0.4)",
                editable: false,
              },
              {
                events: my_events,
                color: "red", // an option!
                textColor: "black", // an option!
                editable: false,
              },
            ]}
            datesSet={handleDates}
            select={handleDateSelect}
            eventAdd={handlePropositionAdd}
            eventChange={handlePropositionChange} // called for drag-n-drop/resize
            eventContent={renderPropositionContent} // custom render function
            eventClick={handlePropositionClick}
            eventDrop={dropEvents}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          />
        </Col>
      </Row>
    </Container>
  );
};
