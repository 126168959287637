export function parseDateString(dateString: string): Date {
  return new Date(dateString);
}
export function parseFormattedDateString(dateString: string): Date {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
}

export function addTwoMonthsToDate(): Date {
  const today = new Date();
  today.setMonth(today.getMonth() + 2);
  return today;
}

export function addDurationToDate(minutes: number): Date {
  const today = new Date();
  today.setMinutes(today.getMinutes() + 90);
  return today;
}
