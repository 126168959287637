import { useNavigate } from "react-router-dom";

import { Button, Col, Row } from "react-bootstrap";
import { MeetingType } from "types/Meeting";
import { InvitedMeetingType } from "../../../types/Dater";

import { useEffect } from "react";
import { useGetMyMeetingsQuery } from "store/features/meetings";
import { useGetParticipantsOfMeetingQuery } from "store/features/participants";
import { useGetUserQuery } from "store/features/user";
// import { cookies } from "next/headers";
import ReactGA from "react-ga4";

type MeetingWithParticipantsPropsType = {
  meeting: MeetingType;
  // participants: InvitedMeetingType[];
};

const get_name = (invited: InvitedMeetingType, id_connected: string) => {
  // normalement, name est rempli mais on fait ça

  if (id_connected === invited.id_person) {
    return "Moi";
  }
  const name = invited["name"]
    ? invited["name"]
    : invited["mail"]?.split("@")[0];
  return name;
};

const InvitedList = ({
  participants,
}: {
  participants: InvitedMeetingType[] | undefined;
}) => {
  const { data: connectedUser } = useGetUserQuery();
  if (!connectedUser) {
    return null;
  }

  const array_of_name = participants
    ? participants.map((participant) => {
        return get_name(participant, connectedUser.id);
      })
    : [];

  let string_of_name = array_of_name.join(", ");
  if (array_of_name.includes("Moi")) {
    let idx = array_of_name.indexOf("Moi");
    array_of_name.splice(idx, 1);
    string_of_name = array_of_name.join(", ") + " et moi";
  }
  return (
    <Row style={{ justifyContent: "center", width: "200px" }}>
      avec {string_of_name}
    </Row>
  );
};

const CardMeeting = ({ meeting }: MeetingWithParticipantsPropsType) => {
  let navigate = useNavigate();
  const { data: participants } = useGetParticipantsOfMeetingQuery(
    meeting["id"]
  );
  return (
    <div
      key={meeting["id"]}
      style={{
        alignItems: "center",
        justifyContent: "center",
        margin: "15px",
        padding: "5px",
        border: "solid",
        borderRadius: "7px",
        borderWidth: "1px",
        // width: "80%",
        backgroundColor: "lightblue",
      }}
    >
      <Row
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col>
          <h2> {meeting["object"]} </h2>
          <InvitedList participants={participants}></InvitedList>
          <Button
            size="sm"
            onClick={() => {
              navigate("/meeting/edit/" + meeting["id"]);
            }}
          >
            {" "}
            Modifier{" "}
          </Button>
          <Button
            size="sm"
            onClick={() => {
              navigate("/meeting/invitations/" + meeting["id"]);
            }}
          >
            {" "}
            Invités{" "}
          </Button>{" "}
          <Button
            size="sm"
            onClick={() => {
              navigate("/availabilities/" + meeting["id"]);
            }}
          >
            {" "}
            Créneaux de la réunion{" "}
          </Button>{" "}
        </Col>
      </Row>
    </div>
  );
};

export const MeetingsDashboard = () => {
  const { data: my_meetings } = useGetMyMeetingsQuery();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string);
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: "/dashboard",
      title: "Dashboard",
    });
  }, []);

  return (
    <div>
      <br />
      <h1>Organiser la réunion qui vous convient</h1>
      Plutôt que de vous demander directement vos disponibilités ou si vous êtes
      disponibles sur des créneaux.
      <br />
      <h2> Prenez le temps de donner vos préférences </h2>
      <br />
      <Row>
        <Col>
          {" "}
          En cours
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {my_meetings
              ? my_meetings
                  .filter((meeting) => meeting["state"] === "en_cours")
                  .map((item: MeetingType) => {
                    return <CardMeeting key={item.id} meeting={item} />;
                  })
              : null}
          </div>
        </Col>
        <Col>
          {" "}
          Une attention est nécessaire
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {my_meetings
              ? my_meetings
                  .filter((meeting) => meeting["state"] === "warning")
                  .map((item: MeetingType) => {
                    return <CardMeeting key={item.id} meeting={item} />;
                  })
              : null}
          </div>
        </Col>
        <Col>
          Fixé via DaTime
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {my_meetings
              ? my_meetings
                  .filter((meeting) => meeting["state"] === "finished")
                  .map((item: MeetingType) => {
                    return <CardMeeting key={item.id} meeting={item} />;
                  })
              : null}
          </div>
        </Col>
      </Row>
      <br />
    </div>
  );
};
