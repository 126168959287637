import { createSlice } from "@reduxjs/toolkit";
import type { MeetingType } from "../types/Meeting";

import { createId } from "../api/createId";
const default_type_RDV = {
  value: 10,
  label: "type_de_rdv",
  times: [{ start: "09:30:00", end: "12:30:00", tz: "UTC" }],
};
const unset_meeting = {
  id: createId(),
  created_by: null,
  simpleType: "normal",
  type: default_type_RDV,
  duration: 60,
  // participants: [],
  location: "",
  object: "Nom public de la réunion",
  state: "en_cours",
} as MeetingType;

const MeetingSlice = createSlice({
  name: "meetingSlice",
  initialState: unset_meeting as MeetingType,
  reducers: {
    createMeeting: (meeting, action) => {
      return action.payload;
    },

    // updateMeeting: (meeting, action) => {
    //     if (meeting) {
    //         return action.payload.meeting
    //     } else {
    //         throw new Error ("Impossible d'updater un meeting qui n'est pas chargé")
    //     }
    // },

    deleteMeeting: (meeting, action) => {
      if (action.payload.id) {
        return { ...unset_meeting, id: action.payload.id } as MeetingType;
      }
      return unset_meeting;
    },

    // addParticipant: (meeting, action) => {
    //   if (meeting) {
    //     meeting["participants"] = [
    //       ...meeting["participants"],
    //       action.payload.invited,
    //     ];
    //     return meeting;
    //   } else {
    //     throw new Error(
    //       "Impossible d'ajouter un participant à un meeting qui n'est pas chargé"
    //     );
    //   }
    // },
    // change l'invité concerné
    // let id = invit.id;
    // const idx = localMeetingPage["participants"].findIndex((x) => x.id === id);
    // const new_invited = [...localMeetingPage["participants"]];
    // const replaced_invited = { ...new_invited[idx], ...{ [index]: value } };
    // new_invited[idx] = replaced_invited;
    // new_invited.splice(idx, 0, new_invited);
    // pousse la nouvelle liste d'invité
    // console.log("handleChange fin", new_invited);
    // updateParticipant: (meeting, action) => {
    //   if (meeting) {
    //     const idx = meeting["participants"].findIndex(
    //       (item: InvitedMeetingType) => item.id === action.payload.invited.id
    //     );

    //     meeting["participants"].splice(idx, 1, action.payload.invited);
    //     return meeting;
    //   } else {
    //     throw new Error(
    //       "Impossible de modifier un participant à un meeting qui n'est pas chargé"
    //     );
    //   }
    // },

    // deleteParticipant: (meeting, action) => {
    //   if (meeting) {
    //     meeting["participants"] = meeting["participants"].filter(
    //       (item: InvitedMeetingType) => item.id !== action.payload.id_person
    //     );
    //     return meeting;
    //   } else {
    //     throw new Error(
    //       "Impossible de retier un participant à un meeting qui n'est pas chargé"
    //     );
    //   }
    // },

    requestUserMeetings: (meeting, action) => {
      return action.payload.plainMeetingObjects;
    },

    requestMeeting: (meeting, action) => {
      return action.payload.plainMeetingObjects;
    },

    requestIdMeeting: (meeting, action) => {
      return action.payload.plainMeetingObjects;
    },

    flushMeetings: (meeting) => {
      return unset_meeting;
    },
  },
});

export const { actions: MeetingActions, reducer: MeetingReducer } =
  MeetingSlice;

export const {
  createMeeting,
  deleteMeeting,
  requestUserMeetings,
  requestIdMeeting,
  flushMeetings,
} = MeetingActions;

export default MeetingSlice.reducer;
