import {
  DatesSetArg,
  EventAddArg,
  EventApi,
  EventChangeArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import { useSelector } from "react-redux";
// import { EventImpl } from '@fullcalendar/core/internal'

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import rrulePlugin from "@fullcalendar/rrule";
import timeGridPlugin from "@fullcalendar/timegrid";

import esLocale from "@fullcalendar/core/locales/es";
import frLocale from "@fullcalendar/core/locales/fr";
import iCalendarPlugin from "@fullcalendar/icalendar";

import { InfoCircle } from "react-bootstrap-icons";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";

import {
  BusinesHoursSelector,
  MaxTimeSelector,
  MinTimeSelector,
  WeekendSelector,
} from "store/selectors";
import { TypeProposition } from "types/Propositions";

import { OverlayTrigger } from "react-bootstrap";
import { from_EventApi_to_Proposition } from "../../../format/change_format";

import { useGetEventsOfUserQuery } from "store/features/events";
import {
  useCreatePropositionMutation,
  useDeletePropositionMutation,
  useGetPropositionsOfUserQuery,
  useUpdatePropositionMutation,
} from "store/features/propositions";
import { proposition_with_new_opinion } from "tools/propositions/change_user_opinion";
// let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
const LOCALES = [esLocale, frLocale];

type MyCalendarProps = {
  // id_meeting: string;
  id_user: string;
  events_sup: TypeProposition[];
};

export const MyCalendar = ({ id_user, events_sup }: MyCalendarProps) => {
  const weekendsVisible = useSelector(WeekendSelector);

  // pour l'import de calendrier

  const { data: my_events } = useGetEventsOfUserQuery(id_user);
  // const { data: myMeeting } = useGetMyMeetingByIDQuery({
  //   id_meeting,
  //   id_person: id_user,
  // });

  const { data: propositions } = useGetPropositionsOfUserQuery(id_user);

  const [createProposition] = useCreatePropositionMutation();
  const [deleteProposition] = useDeletePropositionMutation();
  const [updateProposition] = useUpdatePropositionMutation();

  const bh = useSelector(BusinesHoursSelector);
  const slotMinTime = useSelector(MinTimeSelector);
  const slotMaxTime = useSelector(MaxTimeSelector);
  // const default_location = null; // useSelector(DefaultLocationSelector)
  // const default_rappel = null; // useSelector(DefaultRappelSelector)
  // const default_transport = null; // useSelector(DefaultTransportSelector)

  const handleDates = (rangeInfo: DatesSetArg) => {}; // pas d'appel à la base

  const handlePropositionAdd = (addInfo: EventAddArg) => {
    const temp_proposition = from_EventApi_to_Proposition(
      addInfo.event,
      id_user
    );
    createProposition(temp_proposition);
  };

  const handlePropositionChange = (changeInfo: EventChangeArg) => {
    let temp_proposition = from_EventApi_to_Proposition(
      changeInfo.event,
      id_user
    );
    updateProposition(temp_proposition);
  };

  const handlePropositionClick = (clickInfo: EventClickArg) => {
    let proposition = from_EventApi_to_Proposition(clickInfo.event, id_user);

    const new_proposition = proposition_with_new_opinion(
      proposition,
      id_user,
      "tous les rendez-vous"
    );
    updateProposition(new_proposition);
  };

  // const handleDateSelect = (selectInfo: DateSelectArg) => {
  //   let event_to_add = from_date_to_Proposition(
  //     selectInfo,
  //     id_user,
  //     id_meeting
  //   );
  //   createProposition(event_to_add);
  //   let calendarApi = selectInfo.view.calendar;
  //   calendarApi.unselect(); // clear date selection
  // };

  const renderPropositionContent = (eventContent: EventContentArg) => {
    return (
      <Container>
        <Row>
          <Col sm={10}>
            <b>{eventContent.timeText}</b>
          </Col>
          <Col sm={2}>
            <OverlayTrigger
              overlay={
                <Tooltip id={"tooltip-" + eventContent.event.id}>
                  {
                    <ul style={{ textAlign: "left" }}>
                      Cliquer pour changer la couleur :<li> vert : ok </li>
                      <li> orange : possible mais pas souhaité </li>
                      <li> rouge : impossible </li>
                    </ul>
                  }
                </Tooltip>
              }
            >
              <InfoCircle></InfoCircle>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Col>
            <i>{eventContent.event.title}</i>
          </Col>
        </Row>
      </Container>
    );
  };

  const handleEvents = (local_events: EventApi[]) => {
    // console.log('handleEventsd', local_events)
  };

  const dropEvents = () => {
    console.log("dropEvent");
  };

  const handleClose = () => null;

  return (
    <Container>
      <Row>
        <Col>
          <FullCalendar
            plugins={[
              interactionPlugin,
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              iCalendarPlugin,
              rrulePlugin,
            ]}
            headerToolbar={
              {
                // left: 'prev,next today',
                // center: 'title',
                // right: 'timeGridDay,timeGridWeek,dayGridMonth'
              }
            }
            businessHours={bh}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            contentHeight={"auto"}
            titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
            locale={"fr"}
            locales={LOCALES}
            initialView="timeGridWeek"
            editable={true}
            droppable={true}
            selectable={true}
            allDaySlot={false}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            eventSources={[
              // your event source
              {
                events: propositions?.filter((item) => item.availability === 1),
                color: "green", // an option!
                textColor: "black", // an option!
              },
              {
                events: propositions?.filter((item) => item.availability === 0),
                color: "red",
              },
              {
                events: propositions?.filter(
                  (item) => item.availability > 0 && item.availability < 1
                ),
                color: "orange",
              },
              {
                events: my_events,
                color: "red", // an option!
                textColor: "black", // an option!
                editable: false,
              },
              {
                events: events_sup,
                color: "green", // an option!
                textColor: "black", // an option!
                editable: false,
              },
              // any other event sources...
            ]}
            datesSet={handleDates}
            // select={handleDateSelect}
            eventAdd={handlePropositionAdd}
            eventChange={handlePropositionChange} // called for drag-n-drop/resize
            eventContent={renderPropositionContent} // custom render function
            eventClick={handlePropositionClick}
            eventDrop={dropEvents}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MyCalendar;
