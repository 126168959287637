import { createId } from "api/createId";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { addDurationToDate } from "tools/dates";

import { TypeProposition } from "types/Propositions";

const format_time = (time: Date) => {
  return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

export const AddProposition = ({
  id_user,
  id_meeting,
  proposMeeting,
  setProposMeeting,
  setViewCalendar,
}: {
  id_user: string;
  id_meeting: string;
  proposMeeting: TypeProposition[];
  setProposMeeting: (propositions: TypeProposition[]) => void;
  setViewCalendar: (view: boolean) => void;
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [open, setOpen] = useState(false);
  const [newProposition, setNewProposition] = useState<TypeProposition>({
    id: createId(),
    start: new Date().toISOString(),
    end: addDurationToDate(60).toISOString(),
    availability: 1,
    validated_by: [],
    possible_by: [],
    rejected_by: [],
    submitted_to: [],
    id_meeting: id_meeting,
    is_new: true,
  });

  let start = newProposition.start
    ? new Date(newProposition.start.toLocaleString())
    : new Date();
  let end = newProposition.end
    ? new Date(newProposition.end.toLocaleString())
    : new Date();

  if (!open) {
    // a plus button to add a new proposition
    return (
      <Row style={{ marginTop: "10px" }}>
        <Col
          xs={4}
          style={{
            justifyContent: "end",
            alignItems: "center",
            display: "flex",
          }}
        >
          Ajouter une proposition{" "}
          <Button onClick={() => setOpen(true)}>+</Button>
        </Col>
      </Row>
    );
  }
  return (
    <div style={{ marginTop: "10px" }}>
      <Row>
        {" "}
        <h4>
          {" "}
          Passer en vue calendrier pour entrer facilement vos propositions{" "}
        </h4>
        <div>
          <Button onClick={() => setViewCalendar(true)}>
            {" "}
            Vue calendrier{" "}
          </Button>
        </div>
      </Row>
      {/* <Row key="DoodleNewProposition" style={{ marginTop: "5px" }}>
        <Col
          xs={4}
          style={{
            justifyContent: "end",
            alignItems: "center",
            display: "flex",
          }}
        >
          - le <Button> {start.toLocaleDateString()} </Button>
          entre <Button> {format_time(start)} </Button> et{" "}
          <Button> {format_time(end)} </Button> :
        </Col>
        <Col xs={6} style={{ textAlign: "left" }}>
          <Row>
            <ButtonGroup>
              <ToggleButton
                id={`radio-${newProposition.id}`}
                variant="success"
                value={1}
                key={0}
                active={0 === selectedAnswer}
                size={0 === selectedAnswer ? "lg" : "sm"}
                onClick={() => setSelectedAnswer(0)} // set the selected answer to 0
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {" "}
                Possible
              </ToggleButton>
              <ToggleButton
                id={`radio-${newProposition.id}`}
                value={0.5}
                variant="warning"
                key={1}
                size={1 === selectedAnswer ? "lg" : "sm"}
                active={1 === selectedAnswer}
                onClick={() => setSelectedAnswer(1)} // set the selected answer to 1
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {" "}
                Possible si pas d'autre choix
              </ToggleButton>
              <ToggleButton
                id={`radio-${newProposition.id}`}
                value={0}
                key={2}
                variant="danger"
                size={2 === selectedAnswer ? "lg" : "sm"}
                active={2 === selectedAnswer}
                onClick={() => setSelectedAnswer(2)} // set the selected answer to 2
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {" "}
                Impossible
              </ToggleButton>
              <p
                style={{
                  display: "flex",
                  textAlign: "left",
                  marginLeft: "7px",
                  alignItems: "center",
                }}
              >
                Nouvelle proposition{" "}
                <Button
                  onClick={() => {
                    setOpen(false);
                    setProposMeeting([...proposMeeting, newProposition]);
                  }}
                >
                  {" "}
                  Annuler{" "}
                </Button>
              </p>
            </ButtonGroup>
          </Row> 
        </Col>
      </Row>*/}
    </div>
  );
};
