import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Accueil = () => {
  let navigate = useNavigate();

  return (
    <div>
      <h1>
        Bienvenue sur DaTime, l'outil de prise de rendez-vous qui s'adapte à
        votre agenda
      </h1>
      {/* <p> Plutôt que de vous servir sur la base du dernier arrivé / dernier servi avec des créneaux qui ne vous conviennent pas forcément 
            DaTime, grâce à la technologie Chronopt{copyright} vous permet d'avoir plus rapidement un créneau qui respecte vos contraintes et vos envies.
            </p>
            <h5>
            Fonctionnement : 
            <li>Je précise mes envies et mes disponibilités</li>
            <li>Je reçois dans les jours qui viennent des propositions adaptées </li>
            <li>Je choisis celle que je préfère </li>
            <li>Si je le souhaite, je suis alerté si un créneau possible pour moi se libère </li>
            </h5>
            <p>Des questions ? Regardez notre FAQ. </p>
            <Button>Remplir mes disponibiltés</Button>
            <p>En savoir plus sur comment DaTime peut faciliter votre quotidien ? </p> */}
      <br></br>
      {process.env.REACT_APP_ENV === "production" ? (
        <h1>
          Bienvenue sur DaTime, l'outil de prise de rendez-vous qui s'adapte à
          vos préférences.
        </h1>
      ) : (
        <Button
          disabled={false}
          onClick={() => {
            navigate("/invitation");
          }}
        >
          Voir l'interface invité
        </Button>
      )}
    </div>
  );
};

export default Accueil;
