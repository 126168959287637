// TODO : mettre l'adresse dans le .env

import { helperPostChronopt } from "./post_chronopt_helper";

type requestDataType = {
  userId: string;
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const postChronoSynch = async (data: requestDataType) => {
  try {
    const start = Date.now();

    const json_returned = await helperPostChronopt({
      url_slash: "/synch",
      json: JSON.stringify(data),
    });

    const elapsed = Date.now() - start;
    if (elapsed < 1500) {
      await delay(1500 - elapsed);
    }

    return json_returned; // parses JSON response into native JavaScript objects
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
  }
};
