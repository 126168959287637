import { useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { proposition_with_new_opinion } from "tools/propositions/change_user_opinion";

import { TypeProposition } from "types/Propositions";

const format_time = (time: Date) => {
  return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

type DoodlePropRowProps = {
  prop: TypeProposition;
  id_user: string;
  meeting_name: string;
  proposMeeting: TypeProposition[];
  setProposMeeting: (propositions: TypeProposition[]) => void;
};

export const DoodlePropRow = ({
  prop,
  id_user,
  meeting_name,
  proposMeeting,
  setProposMeeting,
}: DoodlePropRowProps) => {
  //     if self.start.date() == self.end.date():
  //     return "le {} de {} à {} with {}={}".format(self.start.strftime('%a %d %b'), self.start.strftime('%H:%M'), self.end.strftime('%H:%M'), self.value_name, self.value)
  // else:
  //     return "du {} au {} with {}={}".format(self.start.strftime('%a %d %b %HH%M'), self.end.strftime('%a %d %b %HH%M'), self.value_name, self.value)

  let start = prop.start ? new Date(prop.start.toLocaleString()) : new Date();
  let end = prop.end ? new Date(prop.end.toLocaleString()) : new Date();

  const [selectedAnswer, setSelectedAnswer] = useState<
    "validated" | "possible" | "rejected"
  >(
    prop["validated_by"].includes(id_user)
      ? "validated"
      : prop["rejected_by"].includes(id_user)
      ? "rejected"
      : "possible"
  );

  const ButtonAnswer = ({
    answer,
    text,
    variant,
  }: {
    answer: "validated" | "possible" | "rejected";
    text: string;
    variant: string;
  }) => {
    return (
      <Button
        id={`radio-${prop.id}`}
        value={answer}
        key={answer}
        variant={answer === selectedAnswer ? variant : "outline-" + variant}
        // size={answer === selectedAnswer ? "lg" : "sm"}
        onClick={() => {
          setSelectedAnswer(answer);
          const new_prop = proposition_with_new_opinion(
            prop,
            id_user,
            meeting_name,
            answer
          );
          const new_propositions = proposMeeting.filter(
            (item) => item.id !== prop.id
          );
          setProposMeeting([...new_propositions, new_prop]);
          console.log("proposMeeting", proposMeeting);
        }}
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          margin: "4px",
        }}
      >
        {text}
      </Button>
    );
  };

  if (start.getDate() === end.getDate()) {
    return (
      <Row
        key={start.toLocaleString() + end.toLocaleString()}
        style={{ marginTop: "5px" }}
      >
        <Col
          xs={4}
          style={{
            justifyContent: "end",
            alignItems: "center",
            display: "flex",
          }}
        >
          - le {start.toLocaleDateString()} entre {format_time(start)} et{" "}
          {format_time(end)} :
        </Col>
        <Col xs={6} style={{ textAlign: "left" }}>
          <Row>
            <ButtonGroup>
              <ButtonAnswer
                answer={"validated"}
                text={"Possible"}
                variant="success"
              />
              <ButtonAnswer
                answer={"possible"}
                text={"Possible si pas d'autre choix"}
                variant="warning"
              />
              <ButtonAnswer
                answer={"rejected"}
                text={"Impossible"}
                variant="danger"
              />
              <p
                style={{
                  display: "flex",
                  textAlign: "left",
                  marginLeft: "7px",
                  alignItems: "center",
                }}
              >
                accepté par{" "}
                {prop["validated_by"].length + prop["possible_by"].length}{" "}
                participants sur{" "}
                {prop["validated_by"].length + prop["possible_by"].length + 1}{" "}
              </p>
            </ButtonGroup>
          </Row>
        </Col>
      </Row>
    );
  }
  return (
    <Row
      style={{ display: "flex", justifyContent: "left" }}
      key={start?.toLocaleString() + end.toLocaleString()}
    >
      {" "}
      - entre le {start?.toLocaleDateString()} à {start.toLocaleTimeString()} et
      le {end.toLocaleDateString()} à {end.toLocaleTimeString()}{" "}
    </Row>
  );
};
