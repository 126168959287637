import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";

import { postChronoSynch } from "api/chronosynch";
import { useGetUserIdQuery } from "store/features/user";
import logo from "../favicon-128.png";

export const Footer = () => {
  // utiliser le offcanva, c'est cool
  let navigate = useNavigate();
  const { data: user_id } = useGetUserIdQuery();
  const [loading, setLoading] = useState(false);

  const handleSync = async () => {
    setLoading(true);
    if (user_id) {
      await postChronoSynch({ userId: user_id });
    }
    setLoading(false);
  };

  const routeChange = () => {
    let path = `/invitation`;
    navigate(path);
  };

  return (
    <Navbar
      bg="dark"
      expand="lg"
      style={{
        padding: "10px",
        position: "fixed",
        width: "100%",
        bottom: "0",
        fontSize: "small",
        justifyContent: "start",
        alignContent: "center",
      }}
    >
      <Navbar.Brand style={{ alignContent: "center" }} as={Link} to="/">
        <img
          alt=""
          src={logo}
          // src="../datetime/favicon-128.png"
          width="70"
          height="70"
          className="d-inline-block align-center"
        />{" "}
        DaTime
      </Navbar.Brand>
      <Nav>
        <Nav.Link as={Link} to="/faq" className="text">
          F.A.Q
        </Nav.Link>

        {process.env.REACT_APP_ENV !== "production" ? (
          <Nav.Link as={Link} to="/profile" className="text">
            {" "}
            Mes préférences
          </Nav.Link>
        ) : null}
      </Nav>
    </Navbar>
  );
};
