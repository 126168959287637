import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import { Footer } from "components/Footer";
import Navbar from "components/Navbar";
import NavbarInvited from "components/NavbarInvited";
import ErrorPage from "pages/404";
import Accueil from "pages/accueil";
import GlobalStyle from "theme/globalStyle";

import { InputPropositionsMeeting } from "pages/datimer/availabilities/[slug]/page";
import { MeetingsDashboard } from "pages/datimer/dashboard/page";
import { InvitationsPage } from "pages/datimer/invitations/page";
import EditMeeting from "pages/datimer/meeting/edit/page";
import MeetingError from "pages/datimer/meeting/error";
import { Organize } from "pages/datimer/meeting/new/page";
import { MyAgenda } from "pages/datimer/my-agenda/page";
import { ProfileParameters } from "pages/datimer/profile/page";
import { ResetPasswordPage } from "pages/datimer/profile/reset_password";
import { FaqPage } from "pages/faq";
import { InvitedPage } from "pages/invited/[id_meeting]/[id_person]/page";
import { RedirectMeetingWithoutIdInUrl } from "pages/organise_redirect";
import ReSchedule from "pages/rescheduling";
import Decision from "pages/slot_decision";
import styled from "styled-components";

// const copyright = String.fromCodePoint(0x00a9);

const AppPro = () => {
  return (
    <DarkerBackgroundContainer
      className="container-fluid"
      style={{ width: "100%", margin: "0rem", boxSizing: "content-box" }}
    >
      <GlobalStyle />
      <Router>
        <Routes>
          <Route
            element={
              <div>
                {" "}
                <Navbar /> <Outlet></Outlet> <Footer />{" "}
              </div>
            }
          >
            <Route path="*" element={<ErrorPage />} />
            <Route path="/" element={<Accueil />} />
            <Route path="" element={<Accueil />} />
            <Route path="/datime" element={<Accueil />} />
            <Route path="/meeting/new" element={<Organize />} />
            <Route path="/meeting/edit/:id_meeting" element={<EditMeeting />} />
            <Route path="/meeting/error" element={<MeetingError />} />
            <Route
              path="/meeting"
              element={<RedirectMeetingWithoutIdInUrl />}
            />
            {/* <Route path="/meeting/:id_meeting" element={<OrganizeSimple />} /> */}

            <Route
              path="/meeting/invitations/:id_meeting"
              element={<InvitationsPage />}
            />
            <Route path="/my-meetings" element={<MeetingsDashboard />} />
            <Route path="/slot_decision" element={<Decision />}>
              <Route path=":id_meeting" />
              {/* <Route path=':id_meeting/:id_invited' /> */}
            </Route>

            <Route path="/my-agenda/" element={<MyAgenda />}></Route>
            <Route
              path="/availabilities/:id_meeting"
              element={<InputPropositionsMeeting />}
            ></Route>

            <Route path="/rescheduling" element={<ReSchedule />} />
            <Route path="/faq" element={<FaqPage />} />

            <Route path="/profile" element={<ProfileParameters />} />
            <Route path="/new_password" element={<ResetPasswordPage />} />
          </Route>
          <Route
            element={
              <div>
                {" "}
                <NavbarInvited /> <Outlet></Outlet>{" "}
              </div>
            }
          >
            {/* <Route path="/invitation" element={<InvitedPage />} /> */}
            <Route
              path="/invitation/:id_meeting/:id_person"
              element={<InvitedPage />}
            />
          </Route>
        </Routes>
      </Router>
    </DarkerBackgroundContainer>
  );
};

export default AppPro;

const DarkerBackgroundContainer = styled.div`
  background: rgb(var(--background));
  width: "100%";
  margin: "2rem";

  & > *:not(:first-child) {
    margin-top: 5rem;
  }
`;
