// TODO : mettre l'adresse dans le .env

import { TypeDispo } from "types/DaTimeEvents";
import { TypeLunch } from "types/Lunch";
import { TypeProposition } from "types/Propositions";
import { createId } from "./createId";

import { helperPostChronopt } from "./post_chronopt_helper";

export type requestDataType = {
  id_meeting?: string;
  userId: string;
  duree: number;
  debut: string; // DATE_FORMAT = '%Y-%m-d%
  fin: string; // DATE_FORMAT = '%Y-%m-d%
  nb_choices: number;
  excluded_slots: TypeLunch[];
  excluded_days: number[];
};

export type eventAgendIAtype = {
  duration: BigInteger;
  start: string;
  end: string;
  value: number;
  value_name: string;
};

// au cas où mais inutile car on interroge la base
const from_AgendIA_to_dispo = (
  eventagendIA: eventAgendIAtype,
  user_id: string
): TypeDispo => {
  const item = {
    start: new Date(eventagendIA.start).toISOString(),
    end: new Date(eventagendIA.end).toISOString(),
    user_id: user_id,
    id: createId(),
    title: "Proposition via DaTime",
    id_meeting: [""],
    availability: eventagendIA["value"],
  };
  return item;
};

const from_AgendIA_to_proposition = (
  eventagendIA: eventAgendIAtype,
  user_id: string
): TypeProposition => {
  const item = {
    start: new Date(eventagendIA.start).toISOString(),
    end: new Date(eventagendIA.end).toISOString(),
    id: createId(),
    // title: 'Proposition via DaTime',
    id_meeting: "",
    availability: eventagendIA["value"],
    submitted_to: [user_id],
    validated_by: [user_id],
    possible_by: [],
    rejected_by: [],
    is_new: false,
  };
  return item;
};

export const postAgendIAGetCreneau = async (data: requestDataType) => {
  try {
    const json_returned = (await helperPostChronopt({
      url_slash: "/creneau",
      json: JSON.stringify(data),
    })) as eventAgendIAtype[];

    const propositions = json_returned.map((item: eventAgendIAtype) => {
      return from_AgendIA_to_proposition(item, data["userId"]);
    });
    return propositions; // parses JSON response into native JavaScript objects
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
  }
};
